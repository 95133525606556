import { LoadingButton } from '@mui/lab';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { createAnnouncement, deleteAnnouncement, getAnnouncement, updateAnnouncement } from '../services/AppServices';
import { Announcement, AnnouncementType } from '../utils/types';
import { useAlert } from '../utils/useAlert';
import DeleteDialog from '../components/DeleteDialog';
import AnnouncementFiles from '../components/AnnouncementFiles';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import AnnouncementLinks from '../components/AnnouncementLinks';

export default function AddEditAnnouncement() {

  const { type, id } = useParams<{ type: string, id: string }>();
  const [title, setTitle] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [priority, setPriority] = React.useState(0);
  const [period, setPeriod] = React.useState<'week' | 'month' | ''>('');
  const [links, setLinks] = React.useState<any>({});
  const [date, setDate] = React.useState<Dayjs | null>(dayjs(new Date()));
  const showSnackbar = useAlert();
  const { token } = useContext(AuthContext);
  const nav = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [announcement, setAnnouncement] = React.useState<Announcement | null>(null);
  const handleDelete = async () => {
    try {
      const res = await deleteAnnouncement(token, +id!);
      showSnackbar('Silme işlemi başarılı', 'success');
      nav(`/admin/app/${type}`);
    } catch (e) {
      showSnackbar('Bir hata oluştu', 'error');
    }
  }

  const fetchAnnouncement = async () => {
    try {
      const res = await getAnnouncement(token, +id!);
      setTitle(res.title);
      setMessage(res.message);
      setAnnouncement(res);
      setPeriod(res.period || '');
      setLinks(res.links || {});
      setDate(dayjs(res.createdAt));
      if (type === 'anasayfa-duyuru') {
        setPriority(res.priority);
      }
    } catch (e) {
      showSnackbar('Bir hata oluştu', 'error');
    }
  }

  useEffect(() => {
    if (id) {
      fetchAnnouncement();
    }
  }, [id])

  const getTitle = () => {
    switch (type) {
      case 'duyurular':
        return 'Duyuru';
      case 'bultenler':
        return 'Bülten';
      case 'anasayfa-duyuru':
        return 'Anasayfa Duyuru';
      case 'fon-duyurular':
        return 'Fon Duyurusu';
      case 'basin-duyurular':
        return 'Basın Duyurusu';
      default:
        return 'Duyuru';
    }
  }

  const getType = () => {
    switch (type) {
      case 'duyurular':
        return AnnouncementType.DUYURU;
      case 'bultenler':
        return AnnouncementType.BULTEN;
      case 'anasayfa-duyuru':
        return AnnouncementType.BANNER;
      case 'fon-duyurular':
        return AnnouncementType.FON;
      case 'basin-duyurular':
        return AnnouncementType.BASIN;
      default:
        return AnnouncementType.DUYURU;
    }
  }

  const handleSubmit = async () => {
    try {
      if (id) {
        let data: any = {};
        data.title = title;
        data.message = message;
        data.type = getType();
        data.links = links;
        data.createdAt = date?.toDate();
        if (type === 'anasayfa-duyuru') {
          data.priority = priority;
        }
        if (type === 'bultenler') {
          data.period = period;
        }
        await updateAnnouncement(token, +id, data);
        showSnackbar('Duyuru güncellendi', 'success');
        nav(`/admin/app/${type}`);
      } else {
        let data: any = {};
        data.title = title;
        data.message = message;
        data.links = links;
        data.type = getType();
        if (type === 'anasayfa-duyuru') {
          data.priority = priority;
        }
        if (type === 'bultenler') {
          data.period = period;
        }
        const res = await createAnnouncement(token, data);
        showSnackbar('Duyuru eklendi', 'success');
        nav(`/admin/app/${type}/${res.id}/duzenle`);
      }
    } catch (e) {
      showSnackbar("Bir Hata oluştu", 'error');
    }
  }

  return (
    <Box
      sx={{
        p: 2,
        maxWidth: '750px',
        width: '100%',
      }}
    >
      <Typography
        sx={{
          fontSize: '1.5rem',
          fontWeight: 600,
          mb: 2,
        }}
      >
        {getTitle()} {id ? 'Düzenle' : 'Ekle'}
      </Typography>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit()
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <TextField
            label="Başlık"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            variant="outlined"
            fullWidth
            required
          />
          <TextField
            label="Mesaj"
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            variant="outlined"
            fullWidth
            required
            multiline
            rows={4}
          />
          {
            type === 'bultenler' && (
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">Periyot</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={period}
                    label="Period"
                    required
                    onChange={(e) => setPeriod(e.target.value as 'week' | 'month')}
                  >
                    <MenuItem value="week">Haftalık</MenuItem>
                    <MenuItem value="month">Aylık</MenuItem>
                  </Select>
                </FormControl>
              </Box>

            )
          }
          <DatePicker
            label="Tarih"
            value={date}
            onChange={(newValue) => setDate(newValue)}
          />
          {type === 'anasayfa-duyuru' && (
            <TextField
              label="Öncelik"
              name="priority"
              value={priority}
              onChange={(e) => setPriority(parseInt(e.target.value))}
              variant="outlined"
              fullWidth
              type="number"
            />
          )}
          {(type === 'duyurular' || type === 'fon-duyurular' || type === 'basin-duyurular') && (
            <AnnouncementLinks
              links={links}
              setLinks={setLinks}
            />
          )}
          <Box>
            {(type !== 'anasayfa-duyuru' && announcement) && (
              <>
                <AnnouncementFiles
                  announcement={announcement}
                  fetchAnnouncement={fetchAnnouncement}
                />
              </>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: 2,
              mt: 2
            }}
          >
            <Box>
              {id && (
                <Button
                  onClick={() => setDeleteDialogOpen(true)}
                  variant="outlined"
                  color="error"
                >
                  Sil
                </Button>
              )}
            </Box>
            <Box>
              <Button
                onClick={() => {
                  nav(`/admin/app/${type}`);
                }}
                variant="outlined"
                color="error"
                sx={{
                  mr: 2
                }}
              >
                Vazgeç
              </Button>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                sx={{
                }}
                loading={false}
              >
                Kaydet
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </form>
      <DeleteDialog
        title={`${getTitle()} Sil`}
        description={`${getTitle()} silmek istediğinize emin misiniz?`}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        onConfirm={() => {
          handleDelete();
        }}
        deleting={deleting}
      />
    </Box>
  )
}
